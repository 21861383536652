import React, { useState, useEffect } from "react";
import { useCallback } from "react";

const images1 = require.context("../media/set1", false, /\.png$/);
const set1 = images1.keys().map((image) => images1(image));
const images2 = require.context("../media/set2", false, /\.png$/);
const set2 = images2.keys().map((image) => images2(image));
const allFigures = [...set1, ...set2];

function FigureDisplay({ setScore }) {
  const [currentFigure, setCurrentFigure] = useState(null);
  const [startTime, setStartTime] = useState(0);

  const selectRandomFigureAndPosition = useCallback(() => {
    const randomFigure =
      allFigures[Math.floor(Math.random() * allFigures.length)];
    const randomPosition = {
      top: Math.floor(Math.random() * 80) + "%",
      left: Math.floor(Math.random() * 80) + "%",
    };

    setCurrentFigure({
      src: randomFigure,
      position: randomPosition,
    });
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    selectRandomFigureAndPosition();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      selectRandomFigureAndPosition();
    }, 1000);

    return () => clearTimeout(timer);
  }, [currentFigure]);

  const handleClick = () => {
    const elapsedTime = (Date.now() - startTime) / 1000;
    const points = Math.max(100 - Math.round(elapsedTime * 50), 0);

    if (set1.includes(currentFigure.src)) {
      setScore((prevScore) => prevScore + points);
    } else if (set2.includes(currentFigure.src)) {
      setScore((prevScore) => prevScore - 50);
    }
    selectRandomFigureAndPosition();
  };

  return (
    <>
      {currentFigure && (
        <img
          src={currentFigure.src}
          alt="figure"
          style={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: currentFigure.position.top,
            left: currentFigure.position.left,
          }}
          onClick={handleClick}
        />
      )}
    </>
  );
}

export default FigureDisplay;
