import React, { useState, useEffect } from "react";
import FigureDisplay from "./components/FigureDisplay";
import StartScreen from "./components/StartScreen";
import ScoreBoard from "./components/ScoreBoard";
function App() {
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const startNewGame = () => {
    setGameStarted(true);
    setGameOver(false);
    setScore(0);
  };

  {
    useEffect(() => {
      let timer;
      if (gameStarted) {
        timer = setTimeout(() => {
          setGameOver(true);
          setGameStarted(false);
        }, 30000);
      }
      return () => clearTimeout(timer);
    }, [gameStarted]);
    return (
      <>
        {!gameStarted && !gameOver && (
          <StartScreen setGameStarted={setGameStarted} />
        )}
        {gameStarted && <FigureDisplay setScore={setScore} />}
        {gameOver && <ScoreBoard score={score} onRestart={startNewGame} />}
      </>
    );
  }
}

export default App;
