import React from "react";

function ScoreBoard({ score, onRestart }) {
  console.log(onRestart);
  return (
    <>
      <h1>Game Over</h1>
      <p> du har fått {score} poeng </p>
      <button onClick={onRestart}>Start spill igjen</button>
    </>
  );
}

export default ScoreBoard;
